import React, { useState, useEffect } from 'react';


const Header = () => (
  <header className="bg-white shadow-sm">
    <nav className="container mx-auto px-6 py-3 flex justify-between items-center">
      <div className="text-2xl font-bold text-gray-800">Logo</div>
      <div className="hidden md:flex space-x-4">
        <a href="#" className="text-gray-600 hover:text-gray-800">Home</a>
        <a href="#" className="text-gray-600 hover:text-gray-800">Projects</a>
        <a href="#" className="text-gray-600 hover:text-gray-800">About</a>
        <a href="#" className="text-gray-600 hover:text-gray-800">Contact</a>
      </div>
    </nav>
  </header>
);

const Hero = () => (
  <section className="bg-gray-100 py-20">
    <div className="container mx-auto px-6 text-center">
      <h1 className="text-4xl md:text-6xl font-bold mb-2 text-gray-800">Welcome to My Portfolio</h1>
      <p className="text-xl mb-8 text-gray-600">I create beautiful and functional web experiences</p>
      <button className="bg-blue-500 text-white font-bold py-2 px-4 rounded hover:bg-blue-600 transition duration-300">View My Work</button>
    </div>
  </section>
);

const ProjectCard = ({ title, description, imageUrl }) => (
  <div className="bg-white rounded-lg shadow-md overflow-hidden">
    <img src={imageUrl} alt={title} className="w-full h-48 object-cover" />
    <div className="p-6">
      <h3 className="font-bold text-xl mb-2 text-gray-800">{title}</h3>
      <p className="text-gray-600">{description}</p>
    </div>
  </div>
);

const Projects = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    fetch('https://api.lemmod.nl/get_projects.php')
      .then(response => response.json())
      .then(data => setProjects(data))
      .catch(error => console.error('Error fetching projects:', error));
  }, []);

  return (
    <section className="py-20">
      <div className="container mx-auto px-6">
        <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">My Projects</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {projects.map(project => (
            <ProjectCard 
              key={project.id}
              title={project.title} 
              description={project.description} 
              imageUrl={project.imageUrl}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

const Footer = () => (
    <footer className="bg-gray-800 text-white py-8">
      <div className="container mx-auto px-6 text-center">
        <p>&copy; 2024 Your Name. All rights reserved.</p>
      </div>
    </footer>
  );
  
  const ModernWebDesign1 = () => (
    <div className="font-sans">
      <Header />
      <Hero />
      <Projects />
      <Footer />
    </div>
  );

export default ModernWebDesign1;