import React from 'react';
import ModernWebDesign1 from './ModernWebDesign1';

function App() {
  return (
    <div className="App">
      <ModernWebDesign1 />
    </div>
  );
}

export default App;